import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Layout from '../../components/Layout.jsx';
import SEO from '../../components/Seo.jsx';
import Hero from '../../components/Hero.jsx';
import IllustratedText from '../../components/IllustratedText.jsx';
import Card, { Cards } from '../../components/Card.jsx';
import Quote from '../../components/Quote.jsx';
import { InfoBar } from '../../components/InfoSection.jsx';

import Money from '../../icons/Money.jsx';
import Schedule from '../../icons/Schedule.jsx';

import Planning from '../../icons/Planning.jsx';
import HolidaysPlanned from '../../icons/HolidayPlanned.jsx';
import CheckList from '../../icons/CheckList.jsx';
import Share from '../../icons/Share.jsx';
import Compare from '../../icons/Compare.jsx';
import Collaborate from '../../icons/Collaborate.jsx';
import Doctor from '../../icons/Doctor.jsx';
import Suit from '../../icons/Suit.jsx';

const Section = styled.div`
  padding: 50px 30px;

  &:nth-child(odd) {
    background-color: #f8f8f8;
  }
`;

const IndexPage = () => (
  <Layout main lang="en">
    <SEO
      title="Horair.es"
      lang="en"
      keywords={['scheduling', 'planning', '24 / 7', 'timetable', 'shifts']}
    />
    <Hero lang="en" openApp="Try now" learnMore="Features">
      24 / 7 planning scheduling made easy.
    </Hero>
    <Section>
      <IllustratedText Illustration={Schedule}>
        <ul>
          <li>Your team needs to be working day and night.</li>
          <li>No one else than you knows how to edit your planning spreadsheet.</li>
          <li>People loose track of which version of the planning is the current one.</li>
        </ul>
        <p>
          Horair.es helps people like you build and manage <u>complex schedules</u>.
        </p>
      </IllustratedText>
    </Section>
    <Section id="more-info">
      <Cards>
        <Card title="Create you schedules" Illustration={Planning}>
          <p>Have all the information you need to assign people when you need it.</p>
        </Card>

        <Card title="Plan holidays" Illustration={HolidaysPlanned}>
          <p>Assign all the team holidays before diving into the planning.</p>
        </Card>

        <Card title="Check your work" Illustration={CheckList}>
          <p>You can make sure you have been fair to all the team at a glance.</p>
        </Card>

        <Card title="Share with the team" Illustration={Share}>
          <p>Everyone can access the latest version of the planning from a link.</p>
        </Card>

        <Card title="Track changes" Illustration={Compare}>
          <p>See what has changed between different versions of the schedule.</p>
        </Card>

        <Card title="Collaborate" Illustration={Collaborate}>
          <p>Many people can collaborate to edit a schedule.</p>
        </Card>
      </Cards>
    </Section>
    <Section>
      <IllustratedText Illustration={Money} imageLeft>
        <ul>
          <li>Does not cost you more than you use it</li>
          <li>5 CHF (or 4.5€) per person per month</li>
          <li>All functionalities included</li>
        </ul>
      </IllustratedText>
    </Section>
    <Section>
      <IllustratedText Illustration={Doctor} small>
        <Quote>Managing my 40 people team is not easy. With horair.es everything is simpler</Quote>
        <Quote author="Cécile, Chief resident, CHUV Lausanne">
          Because I can access horair.es at anytime with my phone and tablet, I can use my commute
          to work on my schedules.
        </Quote>
      </IllustratedText>
      <IllustratedText Illustration={Suit} imageLeft small>
        <Quote>I can finally know when my wife is working the night shift.</Quote>
      </IllustratedText>
    </Section>
    <Section>
      <InfoBar>
        <Link to="/en/docs">Documentation</Link>
        <a target="_blank" rel="noopener noreferrer" href="https://app.horair.es">
          Try now
        </a>
        <a href="mailto:info@horair.es">Contact us</a>
      </InfoBar>
    </Section>
  </Layout>
);

export default IndexPage;
